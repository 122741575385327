import './App.css';
import Hero from "./Hero/Hero.jsx"
import Header from "./Header/Header.jsx"


function App() {
  return (
    <div className="Aleksa-portfolio">
      <Header></Header>
      <Hero ></Hero>
      </div>
  );
}

export default App;
