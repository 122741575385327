import './BlogPage.css';
import '../App.css';

function BlogPage() {
  return (
    <div >
<h2>This page is in process </h2>
  </div>
  );
}

export default BlogPage;
